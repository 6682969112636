import React from "react"
import Container from "../components/Container";
import Head from "../components/Head";
import Button from "../components/Button"
import PrivacyButtons from "../components/PrivacyButtons";

const Code = () => {
  return (
    <Container>
      {/* DESKTOP */}
      <Head paragraphsClassName="hidden md:block" paragraphs={[
        `Please, open this link on your phone to claim your Dice tickets:`
      ]} />
       <a href={`https://accessticket.page.link/?link=https://accessticket.app/dice&apn=app.testwallet.accessticket&isi=1534897902&ibi=app.testwallet.ticketapp`} className="hidden w-1/6 py-4 mt-12 text-center md:block rounded-xl bg-green">
          <span className="text-base text-black font-americaBold">Add your ticket</span>
        </a>
      {/* MOBILE */}
      <div className="flex flex-col items-center justify-between mt-12 md:hidden">
        <div className="text-center">
          <p className="text-white text-md font-americaLight">Please, open this link on your phone to claim your Dice tickets:</p>
        </div>
        <a href={`https://accessticket.page.link/?link=https://accessticket.app/dice&apn=app.testwallet.accessticket&isi=1534897902&ibi=app.testwallet.ticketapp`} className="block w-full py-4 mt-12 text-center rounded-xl bg-green">
          <span className="text-base text-black font-americaBold">Add your ticket</span>
        </a>
      </div>
      <div className="flex items-center justify-between w-full mt-24 md:w-1/4">
        <a href="https://apps.apple.com/es/app/accessticket/id1534897902" className="flex-1 mr-2 md:mx-3">
          <img src="/assets/appstore.png" alt="AppStore" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=app.testwallet.accessticket" className="flex-1 ml-2 md:mx-3">
          <img src="/assets/playstore.png" alt="GooglePlay" />
        </a>
        <a href="https://appgallery.huawei.com/app/C105891447" className="flex-1 ml-2 md:mx-3">
          <img src="/assets/huawei.png" alt="Huawei" />
        </a>
      </div>
      <PrivacyButtons />

    </Container>
  )
}

export default Code