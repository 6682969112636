import React from 'react';
import Container from '../components/Container';
import Literal from '../literals/Literal';
import { bottomBlueIcon, bottomRightIcon, greenIcon, greenLeftIcon, midIcon, topIcon, whiteCircle } from '../assets/svg/earlyBirdAssets';
import image from '../assets/img/qr_downloads.png';

const DownloadEarlyBird = () => {
  return (
    <Container className="text-white relative" backgroundImage={false}>
      <div className="flex flex-col gap-y-6 w-full md:text-center md:max-w-3xl md:mt-12">
        <span className="font-americaBold text-2xl leading-none mb-6 text-left md:absolute md:top-20 md:left-20">AT</span>
        <h2 className="font-americaBold text-36px leading-none ms:w-4/5 md:mx-auto">{Literal("EB_DOWNLOAD_TITLE")}</h2>
        <div
          className="md:hidden font-americaLight text-base ms:w-4/5"
        >
          <p className="pt-4 pb-5 font-americaLight">{Literal("EB_CLICK_TO_SEE")}</p>
          <a
            href="https://acst.app/marketplaceEvent/667d22e824bebf0009b6b5a4"
            rel="noreferrer"
            target="_blank"
            className="text-sm py-2.5 px-16 text-center md:block font-bold rounded-xl bg-lime text-black w-max cursor-pointer"
          >
            {Literal("EB_CLICK")}
          </a>
        </div>
        <p className="font-americaLight text-base ms:w-4/5 md:mx-auto">{Literal("EB_DOWNLOAD_TEXT")}</p>
        <div className='w-full flex items-center justify-center'>
          <div className='bg-white w-56 h-56 rounded-md border-lime border my-6 p-2'>
            <img src={image} alt="qr code" />
          </div>
        </div>
        <div className='h-1px w-full bg-gray my-4' />
        <div className="flex flex-col w-full gap-y-6 text-left">
          <div>
            <h4 className="font-americaMedium text-md">{Literal("DICE_DOUBTS")}</h4>
            <p className="font-americaLight text-base">{Literal("DICE_HELP")}</p>
          </div>
          <a
            href="https://www.accessticket.app/intercom_v2.html"
            rel="noreferrer"
            target="_blank"
            className="text-sm py-2.5 px-16 text-center md:block font-bold rounded-xl bg-lime text-black w-max cursor-pointer"
          >
            {Literal("DICE_CONTACT")}
          </a>
        </div>
      </div>

      <div className="absolute top-6 right-0 md:right-72 md:-top-6 transform md:-rotate-90 w-16">{topIcon}</div>
      <div className="absolute bottom-10 right-0 w-10 md:hidden">{midIcon}</div>
      <div className="absolute bottom-0 left-24 w-14">{bottomBlueIcon}</div>
      <div className="absolute bottom-20 right-0 w-10 hidden md:block">{bottomRightIcon}</div>
      <div className="absolute bottom-96 right-0 w-14 hidden md:block">{greenIcon}</div>
      <div className="absolute top-0 left-96 w-20 hidden md:block">{whiteCircle}</div>
      <div className="absolute bottom-72 left-0 w-5 hidden md:block">{greenLeftIcon}</div>
    </Container>
  );
};

export default DownloadEarlyBird;
