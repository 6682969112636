import Button from "../components/Button"

const PrivacyButtons = () => {
  return (
    <>
      <div
        className={`md:flex items-center justify-center`}
      >
        <Button
          label="Terms and conditions"
          link="https://www.accessticket.app/conditions"
          className=" m-5 mt-16 md:mt-24"
        />
        <Button
          label="Privacy Policy"
          link="https://www.accessticket.app/privacy-policy"
          className="text-center  m-5 mt-16 md:mt-24"
        />
      </div>
    </>
  );
};

export default PrivacyButtons;
