import { useRef } from "react"
import snarkdown from "snarkdown"

const HTMLText = ({ text, className }) => {
  const ref = useRef(null)

  return (
    <div className={`${className} markdown`}>
      {text && typeof text === "string" && (
        <span
          ref={ref}
          dangerouslySetInnerHTML={{ __html: snarkdown(text) }}
        />
      )}
    </div>
  )
}

export default HTMLText