import React, { useEffect, useState } from 'react';
import Container from '../components/Container';
import Literal from '../literals/Literal';
import copy from '../assets/svg/copy';
import { arrow, bottomBlueIcon, bottomRightIcon, greenIcon, greenLeftIcon, midIcon, topIcon, whiteCircle } from '../assets/svg/earlyBirdAssets';

const EarlyBird = () => {
  const [code, setCode] = useState(null)

  function copyText() {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      return navigator.clipboard.writeText(code);
    }
  }

  useEffect(() => {
    try {
      const url = new URLSearchParams(window.location.search)
      const code = url.get('code')
      setCode(code)
    } catch (e) {
      console.log('E', e)
    }
  }, [])

  return (
    <Container className="text-white relative" backgroundImage={false}>
      <div className="flex flex-col gap-y-6 w-full md:text-center md:max-w-3xl md:mt-12">
        <span className="font-americaBold text-2xl leading-none mb-6 text-left md:absolute md:top-20 md:left-20">AT</span>
        <h2 className="font-americaBold text-36px leading-none w-4/5 md:w-full">{Literal("EB_TITLE")}</h2>
        <p className="font-americaLight text-base w-11/12 md:mx-auto">{Literal("EB_TEXT")}</p>
        <div className='md:max-w-md md:mx-auto w-full h-16 border-gray border border-dashed rounded-xl my-4'>
          <button onClick={() => copyText()} className='flex gap-3 w-full items-center justify-center h-full'>
            <p className='text-white text-2xl font-semibold'>{code}</p>
            <div className='text-green'>{copy}</div>
          </button>
        </div>
        <p className="font-americaLight text-base md:max-w-md md:mx-auto">{Literal("EB_SELECT_SITE")}</p>
        <div className='flex flex-col gap-3 md:max-w-md w-full md:mx-auto'>
          <a
            href={`https://feverup.com/m/186607`}
            rel="noreferrer"
            target="_blank"
            className="py-4 px-8 rounded-xl bg-gray w-full cursor-pointer flex justify-between items-center gap-2"
          >
            <p className='font-bold text-white text-left'>Fever</p>
            <span className='w-2'>{arrow}</span>
          </a>
        </div>

        <div className='h-1px w-full bg-gray my-4' />
        <div className="flex flex-col w-full gap-y-6 text-left">
          <div>
            <h4 className="font-americaMedium text-md">{Literal("DICE_DOUBTS")}</h4>
            <p className="font-americaLight text-base">{Literal("DICE_HELP")}</p>
          </div>
          <a
            href="https://www.accessticket.app/intercom_v2.html"
            rel="noreferrer"
            target="_blank"
            className="text-sm py-2.5 px-16 text-center md:block font-bold rounded-xl bg-lime text-black w-max cursor-pointer"
          >
            {Literal("DICE_CONTACT")}
          </a>
        </div>
      </div>

      <div className="absolute top-6 right-0 md:right-72 md:-top-6 transform md:-rotate-90 w-16">{topIcon}</div>
      <div className="absolute bottom-10 right-0 w-10 md:hidden">{midIcon}</div>
      <div className="absolute bottom-0 left-24 w-14">{bottomBlueIcon}</div>
      <div className="absolute bottom-20 right-0 w-10 hidden md:block">{bottomRightIcon}</div>
      <div className="absolute bottom-96 right-0 w-14 hidden md:block">{greenIcon}</div>
      <div className="absolute top-0 left-96 w-20 hidden md:block">{whiteCircle}</div>
      <div className="absolute bottom-72 left-0 w-5 hidden md:block">{greenLeftIcon}</div>
    </Container>
  );
};

export default EarlyBird;
