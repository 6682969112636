const Head = ({ className, paragraphs, paragraphsClassName }) => {
  return (
    <>
      <div
        className={`flex items-center justify-center w-full md:w-1/3 ${className}`}
      >
        <img src="/assets/logo.png" alt="AccessTicket" className="w-30%" />
        <div className="ml-6 md:ml-12 text-white">
          <h1 className=" text-2xl md:text-6xl font-americaBold">
            AccessTicket
          </h1>
          <h2>The fastest gateway</h2>
        </div>
      </div>
      <div
        className={`text-white text-center mt-8 md:mt-12 w-4/5 md:w-1/2 ${paragraphsClassName}`}
      >
        {paragraphs &&
          paragraphs.map((paragraph) => (
            <p className="mb-8 text-md md:text-lg font-americaLight">
              {paragraph}
            </p>
          ))}
      </div>
    </>
  );
};

export default Head;
