const en = {
  DICE_WELCOME: 'Welcome to AccessTicket',
  DICE_IMPORT: 'Your tickets are almost ready! Click on the button to add your Dice tickets to AccessTicket',
  DICE_SEE_APP: 'Add tickets',
  DICE_FAQS: 'FAQS',
  DICE_DOUBTS : 'Still need help?',
  DICE_HELP: 'Contact our Support & Help team',
  DICE_CONTACT: 'Contact',
  DICE_PLEASE_DOWNLOAD: 'Please download before clicking "Add your ticket"',
  DICE_ADD_YOUR_TICKET: 'Add your ticket',
  // Questions
  DICE_QUESTION_1_TITLE: "The AccessTicket app opens but it stays on the home screen or it doesn’t change.",
  DICE_QUESTION_1_TEXT: `Leave your app open and try clicking on the "Add tickets" button again. If it still doesn’t respond, follow these steps:\n
  \n* Log in to your account or register with a new one.
  \n* Tap on the profile icon on the tab bar then go to the “My data” screen.
  \n* Add and verify your phone number. It must be the same one you used on your DICE account.
  \n* Go back to the profile screen and tap on “Claim DICE Tickets”.`,
  DICE_QUESTION_2_TITLE: "The button takes me to the App Store / Play Store but my app is already installed.",
  DICE_QUESTION_2_TEXT: `Open the app first and then tap on the "Add tickets" button. If the app doesn’t change after clicking the button, follow these steps:\n
  \n* Log in to your account or register with a new one.
  \n* Tap on the profile icon on the tab bar then go to the “My data” screen.
  \n* Add and verify your phone number. It must be the same one you used on your DICE account.
  \n* Go back to the profile screen and tap on “Claim DICE Tickets”.`,
  DICE_QUESTION_3_TITLE: "The DICE Tickets screen is displayed, but it isn’t listing any tickets.",
  DICE_QUESTION_3_TEXT: `Close the app and try clicking on the "Add tickets" button again. If the issue persists, try to follow these steps:\n
  \n* Log in to your account or register with a new one.
  \n* Tap on the profile icon on the tab bar then go to the “My data” screen.
  \n* Add and verify your phone number. It must be the same one you used on your DICE account.
  \n* Go back to the profile screen and tap on “Claim DICE Tickets”.`,
  DICE_QUESTION_4_TITLE: "I have added my phone number to my account but it isn’t listing my DICE tickets.",
  DICE_QUESTION_4_TEXT: `Make sure the phone number you added to your AccessTicket account is the same as the one on your DICE account. If it isn’t, you can update your phone number by following these steps:\n
  \n* Tap on the profile icon on the tab bar then go to the “My data” screen.
  \n* Update and verify your new phone number. It must be the same one you used on your DICE account.
  \n* Go back to the profile screen and tap on “Claim DICE Tickets”.`,
  DICE_QUESTION_5_TITLE: "I can’t validate my phone number.",
  DICE_QUESTION_5_TEXT: "If the code you entered is invalid, please check it’s the same as the one you received via SMS. If you aren’t receiving any SMS with the code, please contact support.",

  // EarlyBird
  EB_TITLE: "This is your code to access the Early Bird",
  EB_TEXT: "This event is only available on Fever. Copy the code and select the point of sale, we will automatically redirect you to the purchase page:",
  EB_SELECT_SITE: "Select your point of sale:",
  EB_REDTKT: "Redtkt Installments",
  EB_FEVER: "FEVER",

  // EarlyBird download page
  EB_DOWNLOAD_TITLE: "Download the AccessTicket app to buy your ticket",
  EB_DOWNLOAD_TEXT: "Use your mobile and scan the QR code to download the app. Once downloaded, you can buy your accreditation for Primavera Pro directly from AccessTicket.",
  EB_DOWNLOAD_TEXT_PS: "Use your mobile and scan the QR code to download the app. Once downloaded, you can buy you Primavera Sound ticket from AccessTicket.",
  EB_CLICK_TO_SEE: "If you're browsing the website from your phone, click the button to download the app and buy your accreditation.",
  EB_CLICK: 'Open AccessTicket',

  EB_SOON: "Soon, you will be able to see your code and get instructions on how to use it.",

  // Compensation
  EB_COMPENSATION_DISCLAIMER: "Remember that this code can only be used once, so if you enter your code in DICE with your user account open but do not complete the purchase process, you do not need to enter the code again. In that case the code will be saved for the next time you log in to complete the purchase.",
  EB_COMPENSATION_TITLE: "Here is your discount code",
  EB_COMPENSATION_DESCRIPTION: "Choose the festival you want to attend and we will redirect you to DICE to make the purchase",
  EB_COMPENSATION_LATAM: `If you want to attend to Primavera Sound LATAM, contact [info@primaverasound.com](mailto:info@primaverasound.com).`,
  EB_COMPENSATION_PRO: `If you want to attend Primavera Pro in Barcelona, contact [infopro@primaverasound.com](mailto:infopro@primaverasound.com).`,
}

export default en