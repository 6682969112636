
export const topIcon = <svg width="100%" height="100%" viewBox="0 0 66 115" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M0.390943 93.561C0.258134 93.0654 0.28216 92.5239 0.494349 92.0137C1.00517 90.7851 2.41566 90.2037 3.64402 90.7148L48.5646 109.402C50.8932 110.371 52.7265 109.262 53.6654 107.901C54.6047 106.539 54.9913 104.432 53.2594 102.599L7.97731 54.665C4.98295 51.4957 4.62513 46.9481 7.08633 43.3489C9.54766 39.7501 13.9154 38.4343 17.9553 40.0751L106.552 76.0665C109.08 77.0935 110.965 75.7287 111.811 74.2482C112.658 72.7674 112.877 70.4505 110.708 68.794L27.0458 4.87157C25.9888 4.06462 25.7866 2.55204 26.5944 1.49481C27.4021 0.437646 28.9139 0.235324 29.9711 1.04297L113.633 64.9651C117.335 67.7934 118.306 72.5935 115.995 76.6385C113.684 80.6833 109.055 82.2837 104.739 80.5302L16.1418 44.5391C13.8153 43.5942 11.993 44.7098 11.0635 46.069C10.1339 47.4279 9.75563 49.5308 11.4798 51.3561L56.7617 99.2898C59.7695 102.473 60.1182 107.033 57.6313 110.637C55.1442 114.241 50.757 115.533 46.714 113.85L1.79322 95.1633C1.0752 94.8646 0.578049 94.2584 0.390943 93.561Z" fill="#5762F9"/>
</svg>

export const midIcon = <svg width="100%" height="100%" viewBox="0 0 54 92" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M77.1987 47.4184C80.4835 49.8326 80.0072 54.8775 76.3287 56.6343L10.4393 88.1019C6.65672 89.9084 2.34896 86.8996 2.74291 82.7263L9.79527 8.01738C10.1892 3.84406 14.9839 1.69459 18.3617 4.17702L77.1987 47.4184Z" stroke="#D8E2E4" strokeWidth="4.90474"/>
</svg>

export const leftMidIcon = <svg width="100%" height="100%" viewBox="0 0 19 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="2.6568" y="-1" width="23.8649" height="23.8649" rx="4.58134" transform="rotate(41 2.6568 -1)" fill="#C2F061"/>
</svg>

export const bottomRightIcon = <svg width="100%" height="100%" viewBox="0 0 39 79" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="39.5128" cy="39.5442" r="35.7608" transform="rotate(75 39.5128 39.5442)" stroke="#5762F9" strokeWidth="6.11779"/>
</svg>

export const bottomLeftIcon = <svg width="100%" height="100%" viewBox="0 0 30 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="18.6746" y="-1.59814" width="23.8649" height="23.8649" rx="4.58134" transform="rotate(58.1551 18.6746 -1.59814)" fill="#D8E2E4"/>
</svg>
