import React from "react"
import Container from "../components/Container";
import Head from "../components/Head";
import Button from "../components/Button"
import PrivacyButtons from "../components/PrivacyButtons";

const Home = () => {
  return (
    <Container>
      <Head paragraphs={[
        'We have created this application so that you can access the most awaited events quickly and without queues.',
        'Register, add your ticket and enjoy.'
      ]}/>
      {/* IMAGES */}
      <div className="w-full mt-12 hidden  md:flex md:items-stretch md:justify-between md:w-1/2">
        <img src="/assets/image_left.png" alt="AccessTicket" className="mb-12 md:mb-0 md:w-2/5"/>
        <img src="/assets/image_right.png" alt="AccessTicket" className="md:w-2/5"/>
      </div>
      {/* STORES */}
      <div className="items-center justify-between w-full mt-24 md:w-1/3 flex">
        <a href="https://apps.apple.com/es/app/accessticket/id1534897902" className="flex-1 mr-2 md:mx-3">
          <img src="/assets/appstore.png" alt="AppStore" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=app.testwallet.accessticket" className="flex-1 ml-2 md:mx-3">
          <img src="/assets/playstore.png" alt="GooglePlay" />
        </a>
        <a href="https://appgallery.huawei.com/app/C105891447" className="flex-1 ml-2 md:mx-3">
          <img src="/assets/huawei.png" alt="Huawei" />
        </a>
      </div>
      <PrivacyButtons />
      <div className="w-3/3">
        <div className="flex items-center flex-row justify-between w-full mt-24 text-white">
          <img width="180px" className="pr-2" src="https://assets-img.primaverasound.com/0x300/psweb/nvude2d1q1y7v7a8tv3v_1697642759168.png" alt="Logotipos" />
          <img width="250px" className="pr-4" src="https://assets-img.primaverasound.com/0x300/psweb/wstdbiir426t2nr434cj_1697643253475.png" alt="Logotipos" />
          <img width="130px" className="pr-2" src="https://assets-img.primaverasound.com/0x300/psweb/9gamesxx489wc1umrhpd_1697642656436.png" alt="Logotipos" />
        </div>
      </div>
    </Container>
  )
}

export default Home