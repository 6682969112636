import en from './en'
import es from './es'
import ca from './ca'

const languages = {
  'en': en,
  'es': es,
  'ca': ca,
}

const Literal = (text) => {
  
  let lang = 'en'
  try {
    lang = navigator.language.includes('-') ? navigator.language.split('-')[0] : navigator.language;
  } catch (e) {
    console.log('E', e)
  }

  try {
    if (typeof languages[lang] !== 'undefined' && typeof languages[lang][text] !== 'undefined') {
      return languages[lang][text]
    } else if (typeof languages['en'] !== 'undefined' && typeof languages['en'][text] !== 'undefined') {
      return languages['en'][text]
    } else {
      return text
    }
  } catch (e) {
    return text
  }
}

export default Literal