
const DownloadApps = () => {
  return (
    <>
      <div className="items-center bg-white justify-between w-full mb-10 md:w-1/2 flex-column border-2 p-8 rounded-lg border-white">
        <div className="text-center">
          <p className="text-black text-xm font-bold font-americaLight pb-5">Please, to use this code you need to first download the app AccessTicket from these stores</p>
        </div>
        <div className="flex items-center">
          <a href="https://apps.apple.com/es/app/accessticket/id1534897902" className="flex-1 mr-2 md:mx-3">
            <img src="/assets/appstore.png" alt="AppStore" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=app.testwallet.accessticket" className="flex-1 md:mx-3">
            <img src="/assets/playstore.png" alt="GooglePlay" />
          </a>
          <a href="https://appgallery.huawei.com/app/C105891447" className="flex-1 ml-2 md:mx-3">
            <img src="/assets/huawei.png" alt="Huawei" />
          </a>
        </div>
      </div>
    </>
  );
};

export default DownloadApps;
