import React, { useEffect, useState } from "react"
import Container from "../components/Container";
import Collapsible from "../components/Collapsible";
import { bottomLeftIcon, bottomRightIcon, leftMidIcon, midIcon, topIcon } from "../assets/svg/diceLinkAssets";
import Literal from "../literals/Literal";

const DiceLink = () => {
  const [isHuawei, setIsHuawei] = useState(false)
  const [fan, setFan] = useState(null)
  const [promoter, setPromoter] = useState(null)
  const domain = window.location.hostname;

  useEffect(() => {
    try {
      const url = new URLSearchParams(window.location.search)
      const fan = url.get('fan')
      const promoter = url.get('promoter')
      setFan(fan)
      setPromoter(promoter)

      const userAgent = navigator.userAgent.toLowerCase();
      const isHuawei = userAgent.includes('huawei') || userAgent.includes('honor');
      setIsHuawei(isHuawei)
    } catch (e) {
      console.log('E', e)
    }
  }, [])

  useEffect(() => {
    if (promoter && fan && isHuawei !== null) {
      if (isExchangePage() && isTouchDevice()) {
        getApp()
      }
    }
  }, [promoter, fan, isHuawei])

  const navigate = (url, blank) => {
    if (blank) {
      window.open(url, '_blank')
    } else {
      window.location = url;
    }
  }

  function isTouchDevice() {
    try {
      return (('ontouchstart' in window) ||
       (navigator.maxTouchPoints > 0) ||
       (navigator.msMaxTouchPoints > 0));
    } catch (e) {
      console.log('E2', e)
      return true;
    }
  }

  function isExchangePage() {
    let isExchangePage = false
    try {
      isExchangePage = window.location.href.includes('/dice/exchange')
    } catch (e) {
      console.log('E', e)
    }
    return isExchangePage
  }

  function getApp() {

    // if (isExchangePage()) {
    //   if (isHuawei) {
    //     navigate('https://appgallery.huawei.com/app/C105891447', true)
    //   } else {
    //     const atURL = `https://www.accessticket.app/dice/exchange?promoter=${promoter}&fan=${fan}`
    //     const url = `https://accessticket.page.link/?link=${encodeURIComponent(atURL)}&apn=app.testwallet.accessticket&isi=1534897902&ibi=app.testwallet.ticketapp&_imcp=1`
    //     navigate(url)
    //   }
    // } else {
    //   navigate(`https://www.accessticket.app/dice/exchange?fan=${fan}&promoter=${promoter}`, true)
    // }
    const currentDomain = domain === "acst.app" ? "accessticket.app" : "acst.app";
    return `https://${currentDomain}/dice/exchange?promoter=${promoter}&fan=${fan}`
  }

  return (
    <Container className="text-white relative" backgroundImage={false}>
      {/* BG ICONS */}
      <div className="flex flex-col gap-y-10 md:max-w-xl">
        <div className="flex flex-col gap-y-6 w-full mb-16 md:mb-0">
          <span className="font-americaBold text-3xl leading-none mb-6">AT</span>
          <h2 className="font-americaBold text-4xl leading-none">{Literal("DICE_WELCOME")}</h2>
          <p className="font-americaLight text-base">{Literal("DICE_IMPORT")}{isExchangePage() ? '!' : '.'}</p>
          <div className="flex-column mt-24 items-center">
            <p className="text-white text-center text-md font-americaLight w-full mb-4">{Literal("DICE_PLEASE_DOWNLOAD")}</p>
            <div className="flex flex-row items-center justify-between w-full  md:w-1/4">

              <a href="https://apps.apple.com/es/app/accessticket/id1534897902" className="flex-1 mr-2 md:mx-3">
                <img src="/assets/appstore.png" alt="AppStore" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=app.testwallet.accessticket" className="flex-1 ml-2 md:mx-3">
                <img src="/assets/playstore.png" alt="GooglePlay" />
              </a>
              <a href="https://appgallery.huawei.com/app/C105891447" className="flex-1 ml-2 md:mx-3">
                <img src="/assets/huawei.png" alt="Huawei" />
              </a>
            </div>
          </div>
          <a href={`${getApp()}`} className="w-full py-4 rounded-xl bg-green mt-12 block text-center">
            <span className="text-base text-black font-americaBold">{Literal("DICE_ADD_YOUR_TICKET")}</span>
          </a>
          {/* <button className="py-3 px-16 text-center md:block rounded-xl bg-lime text-black w-max" onClick={() => getApp()}>{Literal("DICE_SEE_APP")}</button>   */}
        </div>

        <div className="flex flex-col w-full gap-y-6">
          <h3 className="text-2xl font-medium">{Literal("DICE_FAQS")}</h3>
          <div className="flex flex-col gap-3">
            <Collapsible title={Literal("DICE_QUESTION_1_TITLE")} text={Literal("DICE_QUESTION_1_TEXT")} />
            <Collapsible title={Literal("DICE_QUESTION_2_TITLE")} text={Literal("DICE_QUESTION_2_TEXT")} />
            <Collapsible title={Literal("DICE_QUESTION_3_TITLE")} text={Literal("DICE_QUESTION_3_TEXT")} />
            <Collapsible title={Literal("DICE_QUESTION_4_TITLE")} text={Literal("DICE_QUESTION_4_TEXT")} />
            <Collapsible title={Literal("DICE_QUESTION_5_TITLE")} text={Literal("DICE_QUESTION_5_TEXT")} />
          </div>
          <div className="w-full h-px border border-green border-dashed opacity-20" />
        </div>

        <div className="flex flex-col w-full gap-y-6">
          <div>
            <h4 className="font-americaMedium text-md">{Literal("DICE_DOUBTS")}</h4>
            <p className="font-americaLight text-base">{Literal("DICE_HELP")}</p>
          </div>
          <a href="https://www.accessticket.app/intercom_v2.html" rel="noreferrer" target="_blank" className="py-3 px-16 text-center md:block rounded-xl bg-lime text-black w-max cursor-pointer">{Literal("DICE_CONTACT")}</a>
        </div>
      </div>

      <div className="absolute top-20 right-0 w-16">{topIcon}</div>
      <div className="absolute top-50vh right-0 w-14">{midIcon}</div>
      <div className="absolute top-60vh left-0 w-5">{leftMidIcon}</div>
      <div className="absolute bottom-20 right-0 w-10">{bottomRightIcon}</div>
      <div className="absolute bottom-0 left-20 w-5">{bottomLeftIcon}</div>
    </Container>
  )
}

export default DiceLink