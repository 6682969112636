import React from "react"
import Container from "../components/Container";
import Head from "../components/Head";
import Button from "../components/Button"
import PrivacyButtons from "../components/PrivacyButtons";

const Code = () => {
  const domain = window.location.hostname;
  const currentDomain = domain === "acst.app" ? "accessticket.app" : "acst.app";

  return (
    <Container>
      {/* DESKTOP */}
      <Head paragraphsClassName="hidden md:block" paragraphs={[
        `Please, open this link on your phone to open the app:`
      ]} />
      <a href={`https://${currentDomain}/go`} className="hidden w-1/6 py-4 mt-12 text-center md:block rounded-xl bg-green">
          <span className="text-base text-black font-americaBold">Open app</span>
        </a>
      {/* MOBILE */}
      <div className="flex flex-col items-center justify-between mt-12 md:hidden">
        <div className="text-center">
          <p className="text-white text-md font-americaLight">Please, open this link on your phone to open the app</p>
        </div>
        <a href={`https://${currentDomain}/go`} className="block w-full py-4 mt-12 text-center rounded-xl bg-green">
          <span className="text-base text-black font-americaBold">Open app</span>
        </a>
      </div>
      <div className="flex-column mt-24 items-center">
        <p className="text-white text-center text-md font-americaLight w-full mb-4">But don't forget to download it, first.</p>
        <div className="flex flex-row items-center justify-between w-full  md:w-1/4">

        <a href="https://apps.apple.com/es/app/accessticket/id1534897902" className="flex-1 mr-2 md:mx-3">
          <img src="/assets/appstore.png" alt="AppStore" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=app.testwallet.accessticket" className="flex-1 ml-2 md:mx-3">
          <img src="/assets/playstore.png" alt="GooglePlay" />
        </a>
        <a href="https://appgallery.huawei.com/app/C105891447" className="flex-1 ml-2 md:mx-3">
          <img src="/assets/huawei.png" alt="Huawei" />
        </a>
        </div>
      </div>
      <PrivacyButtons />

    </Container>
  )
}

export default Code