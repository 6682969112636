import { useState } from "react"
import HTMLText from "./HTMLText"

const Collapsible = ({ title, text }) => {
  const [opened, setOpened] = useState(false)

  return (
    <div className="bg-gray rounded-2xl p-6 w-full cursor-pointer" onClick={() => setOpened(!opened) }>
      <div className="flex justify-between">
        <h4 className="w-3/4 font-americaMedium text-base">{title}</h4>
        <div className="w-1/4 flex items-center justify-end">
          <div className={`w-4 ${opened ? "transform rotate-180" : ""}`}>
            <svg width="100%" height="100%" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.437529 0.562498C0.364962 0.634962 0.307393 0.72102 0.268115 0.815751C0.228836 0.910483 0.208618 1.01203 0.208618 1.11458C0.208618 1.21713 0.228836 1.31868 0.268115 1.41341C0.307393 1.50814 0.364962 1.5942 0.437529 1.66666L6.94795 8.17708C7.02041 8.24965 7.10647 8.30722 7.2012 8.3465C7.29593 8.38577 7.39748 8.40599 7.50003 8.40599C7.60258 8.40599 7.70413 8.38577 7.79886 8.3465C7.89359 8.30722 7.97965 8.24965 8.05211 8.17708L14.5625 1.66666C14.635 1.59416 14.6925 1.50809 14.7318 1.41337C14.771 1.31864 14.7912 1.21711 14.7912 1.11458C14.7912 1.01205 14.771 0.910523 14.7318 0.815796C14.6925 0.72107 14.635 0.634999 14.5625 0.562498C14.49 0.489998 14.404 0.432487 14.3092 0.39325C14.2145 0.354013 14.113 0.333817 14.0104 0.333817C13.9079 0.333817 13.8064 0.354013 13.7117 0.39325C13.6169 0.432487 13.5309 0.489998 13.4584 0.562498L7.50003 6.51979L1.5417 0.562498C1.46923 0.489932 1.38317 0.432362 1.28844 0.393083C1.19371 0.353804 1.09216 0.333588 0.989612 0.333588C0.88706 0.333588 0.785515 0.353804 0.690783 0.393083C0.596051 0.432362 0.509992 0.489932 0.437529 0.562498Z" fill="#C2F061"/>
            </svg>
          </div>
        </div>
      </div>
      {opened && 
        <HTMLText className="pt-4 font-americaLight" text={text} />
      }
    </div>
  )
}

export default Collapsible