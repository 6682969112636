const ca = {
  DICE_WELCOME: 'Benvingut a AccessTicket',
  DICE_IMPORT: 'Les teves entrades ja quasi estan llestes! Prem al botó per afegir les teves entrades de Dice a AccessTicket',
  DICE_SEE_APP: 'Afegir entrades',
  DICE_FAQS: 'FAQS',
  DICE_DOUBTS : 'Tens més dubtes?',
  DICE_HELP: 'Contacta amb el nostre servei de Suport i Ajuda',
  DICE_CONTACT: 'Contacta',
  DICE_PLEASE_DOWNLOAD: 'Si us plau, descarrega abans de fer clic a "Afegeix el teu tiquet"',
  DICE_ADD_YOUR_TICKET: 'Afegeix el teu tiquet',
  // Questions
  DICE_QUESTION_1_TITLE: "L’app AccessTicket s’obre però es queda a la home o no canvia res.",
  DICE_QUESTION_1_TEXT: `Deixa l’app oberta i prova polsant de nou el botó d’"Afegir entrades". Si segueix sense respondre, segueix aquests passos:\n
  \n* Inicia sessió al teu compte o registra't.
  \n* Polsa l’icona del perfil a la tab bar i accedeix a la pantalla “Les meves dades”.
  \n* Afegeix i verifica el teu telèfon. Ha de ser el mateix que tens al teu compte de DICE.
  \n* Torna a la pantalla del perfil i polsa sobre  “Reclamar entrades DICE”.`,
  DICE_QUESTION_2_TITLE: "El botó em porta a l’App Store / Play Store però ja tinc l’app instal·lada.",
  DICE_QUESTION_2_TEXT: `Obre l'app i clica el botó "Afegir entrades". Si l'app no canvia després de clicar el botó, segueix els passos següents:\n
  \n* Inicia sessió al teu compte o registra't per crear-ne un.
  \n* Feu clic a la icona de perfil a la pestanya i aneu a la pantalla de “Les meves dades”.
  \n* Afegeix i verifica el teu número de telèfon. Ha de ser el mateix que vas utilitzar al teu compte de DICE.
  \n* Torna a la pantalla del teu perfil i clica “Reclama les teves entrades DICE”.`,
  DICE_QUESTION_3_TITLE: "La pantalla de Tickets DICE es mostra, però no apareix cap tiquet.",
  DICE_QUESTION_3_TEXT: `Tanca l'app i intenta fer clic al botó "Afegir entrades" de nou. Si el problema continua, segueix els passos següents:\n
  \n* Inicia sessió al teu compte o registra't per crear-ne un.
  \n* Feu clic a la icona de perfil a la pestanya i aneu a la pantalla de “Les meves dades”.
  \n* Afegeix i verifica el teu número de telèfon. Ha de ser el mateix que vas utilitzar al teu compte de DICE.
  \n* Torna a la pantalla del teu perfil i clica “Reclama les teves entrades DICE”. `,
  DICE_QUESTION_4_TITLE: "He afegit el número de telèfon al meu compte però no apareixen els meus tiquets de DICE.",
  DICE_QUESTION_4_TEXT: `Assegureu-vos que el número de telèfon que heu afegit al vostre compte AccessTicket sigui el mateix que el del vostre compte DICE. Si no és així, podeu actualitzar el vostre número de telèfon seguint aquests passos:\n
  \n* Feu clic a la icona de perfil a la pestanya i aneu a la pantalla de “Les meves dades”.
  \n* Actualitza i verifica el teu número de telèfon. Ha de ser el mateix que vas utilitzar al teu compte de DICE.
  \n* Torna a la pantalla del teu perfil i clica “Reclama les teves entrades DICE”. `,
  DICE_QUESTION_5_TITLE: "No puc validar el meu número de telèfon.",
  DICE_QUESTION_5_TEXT: "Si el codi que has introduit no és vàlid, si us plau, revisa que sigui el mateix codi que has rebut per SMS. Si no has rebut cap SMS amb el codi, si us plau, contacta amb Suport. ",

  // EarlyBird
  EB_TITLE: "Aquest és el teu codi per accedir al Early Bird",
  EB_TEXT: "Aquest esdeveniment només està disponible a Fever. Còpia el codi i fes click a la plataforma de venda, et redirigirem automàticament a la pàgina de compra del producte.",
  EB_SELECT_SITE: "Selecciona la teva plataforma de venda:",
  EB_REDTKT: "Redtkt a terminis",
  EB_FEVER: "FEVER",

  // EarlyBird download page
  EB_DOWNLOAD_TITLE: "Descarrega’t l’app d’AccessTicket per comprar la teva entrada",
  EB_DOWNLOAD_TEXT: "Utilitza el teu dispositiu mòbil i escaneja el codi QR per descarregar l’aplicació. Un cop descarregada, podràs comprar la teva acreditació per Primavera Pro directament des d’AccessTicket.",
  EB_CLICK_TO_SEE: "Si visualitzes la web des del vostre mòbil, clica el botó per poder descarregar l'app i comprar la teva acreditació.",
  EB_CLICK: 'Obrir AccessTicket',

  EB_SOON: "Aviat podràs veure el teu codi i obtenir instruccions sobre com utilitzar-lo.",

  // Compensation
  EB_COMPENSATION_DISCLAIMER: "Recorda que aquest codi només es pot utilitzar una vegada, per tant, si introdueixes el teu codi a un esdeveniment de DICE amb el teu compte d’usuari obert però no completes el procés de compra, no cal que introdueixis el codi de nou. En aquest cas, el codi es guardarà per a la propera vegada que et connectis per completar la compra.",
  EB_COMPENSATION_TITLE: "Aquí tens el teu codi de descompte",
  EB_COMPENSATION_DESCRIPTION: "Tria el festival al qual vols assistir i et redirigirem a DICE perquè puguis realitzar la compra",
  EB_COMPENSATION_LATAM: `Si vols assistir a Primavera Sound LATAM, contacta amb [info@primaverasound.com](mailto:info@primaverasound.com).`,
  EB_COMPENSATION_PRO: `If you want to attend Primavera Pro in Barcelona, contact [infopro@primaverasound.com](mailto:infopro@primaverasound.com).`
}

export default ca