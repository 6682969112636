import React from "react"
import Container from "../components/Container";
import Head from "../components/Head";
import QRCode from "react-qr-code"

import {
  useLocation,
} from "react-router-dom"
import PrivacyButtons from "../components/PrivacyButtons";
import DownloadApps from "../components/DownloadApps";

const Code = () => {
  const { pathname } = useLocation();
  // Obtener el dominio
  const domain = window.location.hostname;
  console.log("🚀 ~ file: Gift.js:15 ~ Code ~ domain:", domain)

  const PathnameParts = pathname?.split('/')
  const TICKETID = PathnameParts[PathnameParts.length - 1]

  const currentDomain = domain === "acst.app" ? "accessticket.app" : "acst.app";

  return (
    <Container >
      <DownloadApps />
      {/* DESKTOP */}
      <Head paragraphsClassName="hidden md:block" paragraphs={[
        `Scan this QR on the app and will add your gift code, or manually enter the following code:`
      ]} />
      <span className="hidden md:block text-white font-bold text-2xl">{TICKETID}</span>
      {/* QR */}
      <div className="hidden mt-12 md:block">
        <div className="p-6 bg-white rounded-5xl">
          <QRCode value={`https://${currentDomain}/gft/${TICKETID}`} />
        </div>
      </div>
      {/* MOBILE */}
      <div className="flex flex-col items-center justify-between md:hidden mt-12">
        <div className="text-center">
          <p className="text-white text-md font-americaLight">Go buy this event by clicking the following button:</p>
        </div>
        <a href={`https://${currentDomain}/marketplaceEvent/${TICKETID}`} className="w-full py-4 rounded-xl bg-green mt-12 block text-center">
          <span className="text-base text-black font-americaBold">Go to shop</span>
        </a>
        <div className="text-center">
          <p className="text-white text-sm font-americaLight">if the button does nothing try to first open the app and create and account</p>
        </div>
      </div>

      <PrivacyButtons />

    </Container>
  )
}

export default Code