const es = {
  DICE_WELCOME: 'Bienvenido a Access Ticket',
  DICE_IMPORT: '¡Tus entradas están casi listas! Pulsa el botón para importar tus entradas de Dice a AccessTicket',
  DICE_SEE_APP: 'Añadir entradas',
  DICE_FAQS: 'FAQS',
  DICE_DOUBTS : '¿Sigues teniendo dudas?',
  DICE_HELP: 'Ponte en contacto con nuestro servicio de Soporte y Ayuda.',
  DICE_CONTACT: 'Contactar',
  DICE_PLEASE_DOWNLOAD: 'Por favor, descarga antes de hacer clic en "Añadir tu entrada"',
  DICE_ADD_YOUR_TICKET: 'Añadir tu entrada',
  // Questions
  DICE_QUESTION_1_TITLE: "La app AccessTicket se abre pero se queda en la home o no cambia nada.",
  DICE_QUESTION_1_TEXT: `Deja la app abierta y prueba pulsando de nuevo en el botón "Añadir entradas". Si sigue sin responder, sigue estos pasos:\n
  \n* Inicia sesión en tu cuenta o regístrate.
  \n* Pulsa el icono del perfil en la tab bar y accede a la pantalla “Mis datos”.
  \n* Añade y verifica tu teléfono. Debe ser el mismo que usaste en tu cuenta de DICE.
  \n* Vuelve a la pantalla de perfil y pulsa sobre “Reclamar entradas DICE”.`,
  DICE_QUESTION_2_TITLE: "El botón me lleva a la App Store / Play Store pero ya tengo la app instalada.",
  DICE_QUESTION_2_TEXT: `Abre la app y clica el botón de "Añadir entradas". Si la app no cambia después de clicar el botón, sigue los siguientes pasos:\n
  \n* Inicia sesión en tu cuenta o regístrate para crear una.
  \n* Haz clic en el icono de perfil en la pestaña y ve a la pantalla de “Mis datos”.
  \n* Añade y verifica tu número de teléfono. Debe ser el mismo que usaste en tu cuenta de DICE.
  \n* Vuelve a la pantalla de tu perfil y clica “Reclamar entradas DICE”.`,
  DICE_QUESTION_3_TITLE: "La pantalla de Tickets DICE se muestra, pero no aparece ninguna entrada.",
  DICE_QUESTION_3_TEXT: `Cierra la app e intenta hacer clic en el botón "Añadir entradas" de nuevo. Si el problema continúa, sigue los siguientes pasos:\n
  \n* Inicia sesión en tu cuenta o regístrate para crear una nueva.
  \n* Haz clic en el icono de perfil en la pestaña y ve a la pantalla de “Mis datos”.
  \n* Añade y verifica tu número de teléfono. Add and verify your phone number.Debe ser el mismo que usaste en tu cuenta de DICE.
  \n* Vuelve a la pantalla de tu perfil y clica “Reclamar entradas DICE”.`,
  DICE_QUESTION_4_TITLE: "He añadido el número de teléfono a mi cuenta pero no aparecen mis tickets de DICE.",
  DICE_QUESTION_4_TEXT: `Asegúrate de que el número de teléfono que añadiste a tu cuenta AccessTicket sea el mismo que el de tu cuenta DICE. Si no es así, puedes actualizar tu número de teléfono siguiendo estos pasos:\n
  \n* Haz clic en el icono de perfil en la pestaña y ve a la pantalla de “Mis datos”.
  \n* Actualiza y verifica tu número de teléfono. Add and verify your phone number.Debe ser el mismo que usaste en tu cuenta de DICE.
  \n* Vuelve a la pantalla de tu perfil y clica “Reclamar entradas DICE”.`,
  DICE_QUESTION_5_TITLE: "No puedo validar mi número de teléfono.",
  DICE_QUESTION_5_TEXT: "Si el código que has introducido no es válido, por favor, revisa que sea el mismo código que has recibido por SMS. Si no has recibido ningún SMS con el código, por favor contacta con Soporte.",

  // EarlyBird
  EB_TITLE: "Este es tu código para acceder al Early Bird",
  EB_TEXT: "Este evento sole está disponible en Fever. Copia el código y haz click en la plataforma de venta, te redirigiremos automáticamente a la página de compra.",
  EB_SELECT_SITE: "Selecciona tu plataforma de venta:",
  EB_REDTKT: "Redtkt a plazos",
  EB_FEVER: "FEVER UP",

  // EarlyBird download page
  EB_DOWNLOAD_TITLE: "Descárgate la app de AccessTicket para comprar tu entrada",
  EB_DOWNLOAD_TEXT: "Utiliza tu dispositivo móvil y escanea el código QR para descargar la aplicación. Una vez descargada, podrás comprar tu acreditación para Primavera Pro directamente desde AccessTicket.",
  EB_CLICK_TO_SEE: `Si visualizas la web desde tu móvil, clica el botón para poder descargar la app y comprar tu acreditación.`,
  EB_CLICK: 'Abrir AccessTicket',

  EB_SOON: "Pronto podrás ver tu código y obtener instrucciones sobre cómo usarlo.",

  // Compensation
  EB_COMPENSATION_DISCLAIMER: "Recuerda que este código es de un único uso, por lo que, si eliges un evento al que asistir e introduces tu código en DICE con tu cuenta de usuario abierta aunque no termines el proceso de compra, el código queda guardado para finalizarla en dicho evento.",
  EB_COMPENSATION_TITLE: "Aquí tienes tu código de descuento",
  EB_COMPENSATION_DESCRIPTION: "Elige el festival al que deseas asistir y te redireccionaremos a DICE para que puedas realizar la compra",
  EB_COMPENSATION_LATAM: `Si quieres asistir a Primavera Sound LATAM, contacta con [info@primaverasound.com](mailto:info@primaverasound.com).`,
  EB_COMPENSATION_PRO: `Si quieres asistir a Primavera Pro en Barcelona, contacta con [infopro@primaverasound.com](mailto:infopro@primaverasound.com).`,
}

export default es