const Button = ({ label, link, className = "" }) => {
  return (
    <div className={className}>
      <a href={link} className="px-8 py-2 text-white border border-white rounded-full text-md font-americaLight">
        {label}
      </a>
    </div>
  )
}

export default Button